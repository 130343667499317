import { useContext, useEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useQuery } from "@apollo/client";
import { GET_NOTIFICATION_HUB_TOKEN } from "graphql/queries";
import { LoggerService } from "services";
import { useDispatch } from "react-redux";
import {
  newAlertToLotEmailCms,
  setEditedFarmGroupId,
  newGeneratedReport,
  setClientId,
  setPgaFormEditing,
  setPgaFormReviewing,
} from "store";
import {
  NotificationTypes,
  INotification,
  IFarmGRoupCmsNotificationData,
} from "helpers";
import { AppContext } from "helpers/contexts/AppContext";

const SignalR: React.FC = (): JSX.Element => {
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const { access_token } = useContext(AppContext);
  const { refetch: getNotificationHubToken } = useQuery(
    GET_NOTIFICATION_HUB_TOKEN
  );
  const dispatch = useDispatch();

  function getCookie(name:string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  useEffect(() => {
    if (access_token) {
      const connect = new HubConnectionBuilder()
        .withUrl(
          `${window.REACT_APP_API_GATEWAY_URL}/notification/hub`,
          {
            skipNegotiation: true,
            transport: 1, //1: WebSocket, 2 :SSE, 4: LongPoll
            accessTokenFactory: async () => {
              const response = await getNotificationHubToken();
              const token = response.data?.getTokenForNotificationHub?.hubToken;

              return token;
            },
          }
        )
        .withAutomaticReconnect()
        .build();
      setConnection(connect);
    }
  }, [getNotificationHubToken, access_token]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("NOTIFICATION", (notification: INotification | any) => {
            if (notification.type === NotificationTypes.CmsReport) {
              const newUpdate = Date.now();
              dispatch(newGeneratedReport(newUpdate));
            }
            if (
              notification.type === NotificationTypes.ToggleMaintenanceStatus
            ) {
              window.location.reload();
            }
            if (notification.type === NotificationTypes.AlertToLotEmailCms) {
              const newUpdate = Date.now();
              dispatch(newAlertToLotEmailCms(newUpdate));
            }
            if (notification.type === NotificationTypes.RefreshPbiReports) {
              window.location.reload();
            }
            if (notification.type === NotificationTypes.LoginSuccessEvent) {
              if (notification.data.timeStamp && notification.data.clientId) {
                const signinTimeStamp = getCookie("signinTimeStamp");
                if(signinTimeStamp === notification.data.timeStamp)
                {
                  dispatch(setClientId(notification.data.clientId));
                }
              }
            }
            if (notification.type === NotificationTypes.FormEditingNotification) {
              dispatch(setPgaFormEditing({userId:notification.data.userId, producerId : notification.data.producerId, userEmail : notification.data.userEmail}));
            }
            if (notification.type === NotificationTypes.FormReviewingNotification) {
              dispatch(setPgaFormReviewing({userId:notification.data.userId, producerId : notification.data.producerId, userEmail : notification.data.userEmail}));
            }
            if (
              notification.type === NotificationTypes.FarmGroupCmsNotification
            ) {
              const { groupId, action } =
                notification.data as IFarmGRoupCmsNotificationData;

              if (action === "EditGroup") {
                dispatch(setEditedFarmGroupId(groupId));
              }
            }
          });
        })
        .catch((error) => {
          LoggerService.error(error);
        });
    }

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [connection, dispatch]);
  return <></>;
};
export default SignalR;
